$msv-checkout-billing-address-margin: 16px;

$msv-checkout-input-margin-left: 0;
$msv-checkout-input-dimensions: 18px;

$msv-checkout-shipping-address-label-margin-bottom: 12px;
$msv-checkout-shipping-address-checkbox-margin-left: 8px;

$msv-checkout-address-detail-margin-left: 0;

.ms-checkout-billing-address {
    font-size: $msv-text-size;
    line-height: $msv-text-line-height;

    &__heading {
        font-weight: $msv-font-weight-600;
        margin-bottom: $msv-checkout-billing-address-margin;
    }

    .msc-address-detail {
        margin-top: $msv-checkout-billing-address-margin;
        margin-left: $msv-checkout-address-detail-margin-left;
    }

    &__shipping-address-label {
        display: flex;
        margin-bottom: $msv-checkout-shipping-address-label-margin-bottom;
    }

    &__input {
        min-width: $msv-checkout-input-dimensions;
        min-height: $msv-checkout-input-dimensions;
        margin-left: $msv-checkout-input-margin-left;
    }

    &__shipping-address-checkbox-text {
        margin-left: $msv-checkout-shipping-address-checkbox-margin-left;
        align-self: center;
    }
}
