$msv-rating-color-transparent: transparent;

.msc-rating {
    position: relative;

    &__group {
        display: inline-block;
    }

    &__range {
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        overflow: visible;
        color: $msv-rating-color-transparent;
        background: $msv-rating-color-transparent;
        border: 0 none;
        opacity: 0;
        z-index: -1;

        &:focus {
            + .msc-rating__group {
                border: 1px dashed $msv-white;
                outline: 1px dashed $msv-black;
            }
        }
    }

    &__star {
        @include add-icon($msv-FavoriteStarFill);
        color: $msv-gray-160;
    }

    &__half-star {
        @include add-icon($msv-FavoriteStarFill);
        @include add-icon($msv-half-star, after);
        color: $msv-gray-60;
        position: relative;

        &::after {
            color: $msv-gray-160;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }
    }

    &__empty-star {
        @include add-icon($msv-FavoriteStarFill);
        color: $msv-gray-60;
    }

    &__star,
    &__half-star,
    &__empty-star {
        margin-right: 8px;
    }

    &__count {
        display: inline;
    }
}
