$transition-collapse: min-height 0.35s ease;

.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    @include transition($transition-collapse);
    min-height: 0;
    height: 0;
    overflow: hidden;
    position: relative;
}
