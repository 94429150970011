$msv-password-reset-verification-width: 40%;
$msv-password-reset-verification-width-m: 80%;
$msv-password-reset-verification-message-margin-top: 20px;
$msv-password-reset-verification-message-padding: 20px;
$msv-password-reset-verification-success-border: 1px solid $msv-text-color;
$msv-password-reset-verification-success-bg-color: $msv-gray-60;
$msv-password-reset-verification-success-text-color: $msv-text-color;
$msv-password-reset-verification-error-border: 1px solid $msv-error-color;
$msv-password-reset-verification-error-bg-color: $msv-error-color;
$msv-password-reset-verification-account-item-margin-top: 20px;
$msv-password-reset-verification-input-margin-top: 4px;
$msv-password-reset-verification-email-verification-buttons-margin-top: 12px;
$msv-password-reset-verification-button-group-spacing: 32px;
$msv-password-reset-verification-button-margin-top: 20px;

.ms-password-reset-verification {
    @include font-content-m();
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: $msv-password-reset-verification-width;
    }

    &__heading {
        @include font-content-xl();
    }

    &__account-item {
        margin-top: $msv-password-reset-verification-account-item-margin-top;

        &-input {
            @include form-input-el();
            margin-top: $msv-password-reset-verification-input-margin-top;
            width: 100%;
        }
    }

    &__email-verification-buttons {
        margin-top: $msv-password-reset-verification-email-verification-buttons-margin-top;
    }

    &__account-item-email_ver_input {
        .ms-password-reset-verification {
            &__account-item-label {
                display: none;
            }

            &__account-item-email_ver_input {
                display: none;
            }
        }
    }

    &__verify-email-edit {
        display: none;
    }

    /* Override inline style inject by AAD so we need important */
    &__page-success[aria-hidden="false"],
    &__page-error[aria-hidden="false"] {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        display: block !important;
    }

    &__page-success {
        color: $msv-password-reset-verification-success-text-color;
        background-color: $msv-password-reset-verification-success-bg-color;
        border: $msv-password-reset-verification-success-border;
        display: none;
        margin-top: $msv-password-reset-verification-message-margin-top;
        padding: $msv-password-reset-verification-message-padding;
    }

    &__page-error {
        color: var(--msv-font-secondary-color);
        background-color: $msv-password-reset-verification-error-bg-color;
        border: $msv-password-reset-verification-error-border;
        display: none;
        margin-top: $msv-password-reset-verification-message-margin-top;
        padding: $msv-password-reset-verification-message-padding;
    }

    &__email-verification-button,
    &__continue-button {
        @include primary-button();
        margin-top: $msv-password-reset-verification-button-margin-top;
        width: 100%;
    }

    &__cancel-button {
        @include secondary-button();
        margin-top: $msv-password-reset-verification-button-margin-top;
        width: 100%;
    }

    &__verify-email-verify,
    &__verify-email-resend,
    &__continue-button:disabled {
        display: none;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: $msv-password-reset-verification-width-m;
        }
    }
}
