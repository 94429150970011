$border-bottom: 1px solid $msv-gray-60;
$msv-order-history-padding-bottom: 28px;
$msv-order-history-order-information-margin-top: 20px;
$msv-order-history-order-information-margin-bottom: 13px;
$msv-order-history-header-padding-bottom: 20px;
$msv-order-history-header-border-bottom: $border-bottom;
$msv-order-history-order-count-margin: 5px;
$msv-order-history-sales-order-border-bottom: $border-bottom;
$msv-order-history-order-information-sales-id-padding-right: 5px;
$msv-order-history-order-information-sales-id-border-right: $border-bottom;
$msv-order-history-order-information-receipt-id-padding-right: 5px;
$msv-order-history-order-information-receipt-id-border-right: $border-bottom;
$msv-details-order-information-channel-name-margin-bottom: 8px;
$msv-order-history-sales-lines-margin-top: 8px;
$msv-order-history-sales-line-picture-margin-right: 12px;
$msv-order-history-sales-line-picture-margin-top: 12px;
$msv-order-history-sales-line-picture-width: 81px;
$msv-order-history-sales-line-picture-height: 81px;
$msv-order-history-empty-image-width:81px;
$msv-order-history-btn-order-details-margin-bottom: 20px;
$msv-order-history-order-information-created-date-padding-right: 5px;
$msv-order-history-order-information-created-date-padding-left: 5px;
$msv-order-history-order-information-created-date-border-right: $border-bottom;
$msv-order-history-order-information-count-padding-right: 5px;
$msv-order-history-order-information-count-padding-left: 5px;
$msv-order-history-order-information-count-border-right: $border-bottom;
$msv-order-history-order-information-amount-padding-left: 5px;
$msv-container-padding-left: 60px;
$msv-container-padding-right: 60px;
$msv-container-width: 100%;
$msv-order-history-group-margin-bottom: 20px;
$msv-order-history-group-delivery-heading-margin-right: 3px;
$msv-order-history-btn-keep-shopping-margin-bottom: 20px;
$msv-order-history-alert-margin-top: 20px;
$msv-order-history-alert-margin-bottom: 20px;
$msv-order-history-btn-more-margin-top: 20px;

:root {
    // view mode
    --msv-order-history-view-mode-selected-btn-background: var(--msv-selected-button-background);
    --msv-order-history-view-mode-selected-hovered-btn-background: var(--msv-selected-hovered-button-background);
}

.ms-order-history {
    color: var(--msv-font-primary-color);
    padding-bottom: $msv-order-history-padding-bottom;

    &__heading {
        @include font-content-xl();
        display: inline-block;
    }

    &__header {
        padding-bottom: $msv-order-history-header-padding-bottom;
        border-bottom: $msv-order-history-header-border-bottom;
        display: flex;
        flex-wrap: wrap;
    }

    &__ms-order-history-view-modes {
        @media screen and (min-width: $msv-breakpoint-m) {
            border-left: 1px solid var(--msv-order-history-border);
            float: right;
        }

        button {
            margin: 0 6px;
            padding: 0 14px;
            color: $msv-primary;
            background: none;
        }

        &:hover, &.msc-view-modes__selected {
            background: var(--msv-order-history-view-mode-selected-btn-background);
        }

        &:hover.msc-view-modes__selected {
            background-color: var(--msv-order-history-view-mode-selected-hovered-btn-background);
        }

        &__list-view {
            @include add-icon($msv-List);
        }

        &__detailed-view {
            @include add-icon($msv-TH-Large);
        }
    }

    &__ms-order-history-filter {
        margin-left: auto;

        @media screen and (min-width: $msv-breakpoint-m) {
            margin-right: 5px;
        }

        &__expand-filter-button {
            background-color: unset;

            &::after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: var(--msv-header-font-size);
                text-align: center;
                vertical-align: text-bottom;
                line-height: 1.2;
                margin: 0 8px;
            }
        }

        &__expand-filter-button[aria-expanded="true"] {
            &::after {
                content: $msv-ChevronUp;
            }
        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: $border-bottom;
            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;

            button {
                align-items: center;
                background-color: transparent;
                border: transparent;
                display: flex;
                font-size: 16px;
                line-height: 20px;
                padding: 0 20px;
                height: 48px;
                cursor: pointer;
                width: 100%;

                &:hover {
                    text-decoration: none;
                }
            }

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: "";
                    display: block;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    &__order-count {
        display: inline-block;
        margin-top: 10px;
        margin-left: $msv-order-history-order-count-margin;
        margin-right: $msv-order-history-order-count-margin;
    }

    &__sales {
        &-order {
            position: relative;
            padding-bottom: $msv-order-history-padding-bottom;
            border-bottom: $msv-order-history-sales-order-border-bottom;

            display: flex;
            flex-wrap: wrap;

            &__expand-products-button {
                background-color: unset;

                text-decoration-line: underline;
                font-size: 16px;
                line-height: 21px;

                @media screen and (min-width: $msv-breakpoint-m) {
                    margin-top: 80px;
                }
            }

            &__expand-products-button-wrapper {
                flex-basis: 100%;
            }
        }

        &-status {
            display: none;
        }
    }

    &__order-information {
        margin-top: $msv-order-history-order-information-margin-top;
        margin-bottom: $msv-order-history-order-information-margin-bottom;
        flex-basis: 100%;
    }

    &__order-information-channel-name {
        display: block;
        font-size: $msv-heading-text-size;
        font-weight: $msv-font-weight-600;
        line-height: $msv-heading-line-height;
        margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
    }

    &__order-information-sales-id,
    &__order-information-receipt-id,
    &__order-information-channel-reference-id {
        display: block;
    }

    &__sales-lines {
        margin-top: $msv-order-history-sales-lines-margin-top;
        display: flex;
        flex-wrap: wrap;
    }

    &__sales-line-picture {
        margin-right: $msv-order-history-sales-line-picture-margin-right;
        margin-top: $msv-order-history-sales-line-picture-margin-top;
        width: $msv-order-history-sales-line-picture-width;
        height: $msv-order-history-sales-line-picture-height;
    }

    &__btn-order-details {
        @include secondary-button();
        margin-top: 20px;
        top: 40px;
        right: 0;
    }

    &__order-information-created-date {
        padding-right: $msv-order-history-order-information-created-date-padding-right;
        padding-left: $msv-order-history-order-information-created-date-padding-left;
        border-right: $msv-order-history-order-information-created-date-border-right;
    }

    &__order-information-count {
        padding-right: $msv-order-history-order-information-count-padding-right;
        padding-left: $msv-order-history-order-information-count-padding-left;
        border-right: $msv-order-history-order-information-count-border-right;
    }

    &__order-information-amount {
        padding-left: $msv-order-history-order-information-amount-padding-left;
    }

    &__group {
        margin-bottom: $msv-order-history-group-margin-bottom;

        &-delivery {
            &-heading {
                font-weight: $msv-font-weight-bold;
                margin-right: $msv-order-history-group-delivery-heading-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }
    }

    &__address {
        display: none;
    }

    &__empty-message,
    &__alert {
        display: block;
        margin-top: $msv-order-history-alert-margin-top;
        margin-bottom: $msv-order-history-alert-margin-bottom;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__btn-order-details {
            width: 100%;
            margin-bottom: $msv-order-history-btn-order-details-margin-bottom;
            position: unset;
        }

        &__btn-keep-shopping {
            width: 100%;
        }
    }

    @include image($msv-order-history-empty-image-width);

    &__btn-more {
        &.is-busy {
            @include add-spinner(before);
        }
        margin-top: $msv-order-history-btn-more-margin-top;

        @include primary-button();
    }

    &__table-list {
        width: 100%;
        border-collapse: collapse;

        &__header {
            text-align: left;

            @include divider(bottom);

            &__row {
                height: 50px;

                th {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                }

                &__column-with-number {
                    text-align: right;
                }
            }
        }

        &__row {
            height: 54px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;

            @include divider(bottom);

            &__view-details-button {
                @include secondary-button();
            }

            &__mobile-cell {
                &__open-details {
                    display: grid;
                    cursor: pointer;
                    padding: 10px 0;
                }

                &__mobile-order-id {
                    font-weight: bold;
                }

                &__mobile-created-date {
                    font-size: 14px;
                    line-height: 18px;
                    padding-top: 8px;
                }

                &__view-details-button {
                    @include secondary-button();
                }
            }

            &__column-with-number {
                text-align: right;
            }
        }

        th, td {
            padding: 20px;

            &.ms-order-history__table-list__header__row__mobile-cell, &.ms-order-history__table-list__row__mobile-cell {
                padding: unset;
            }
        }
    }
}
