$rtl-product-speicification-drawer-details-right: 12px;

[dir="rtl"] {
    .ms-product-specification__accordion {
        .ms-product-details {
            padding-right: $rtl-product-speicification-drawer-details-right;
        }

        .drawer__buttontext {
            float: right;
        }

        .ms-product-specification__drawer-close,
        .ms-product-specification__drawer-open {
            float: left;
        }

        .ms-product-specification-title {
            .ms-product-specification__drawer {
                .drawer__button {
                    &[aria-expanded="false"],   &[aria-expanded="true"] {
                        padding-right: 12px;
                    }
                }
            }
        }
    }
}
