$media-gallary-padding-left: 40px;
$media-gallary-padding-right: 0;
$msv-buybox-content-width: 30%;

[dir="rtl"] {
    .ms-buybox {
        &__media-gallery {
            padding-right: $media-gallary-padding-right;
        }

        &__content {
            min-width: $msv-buybox-content-width;
        }

        .msc-ss-carousel-vert-strip img {
            display: inline-block;
        }

        .ms-buybox__key_in_price_custom-amount__input {
            text-align: right;
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        .ms-media-gallery {
            .msc-carousel {
                &__control__prev {
                    left: unset;
                    right: 0;

                    &__icon {
                        @include add-icon($msv-ChevronRight);
                    }
                }

                &__control__next {
                    right: unset;
                    left: 0;

                    &__icon {
                        @include add-icon($msv-ChevronLeft);
                    }
                }
            }
        }
    }
}
