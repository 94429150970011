$msv-order-template-heading-height: 40px;
$msv-order-template-block-margin-bottom: 20px;

$msv-order-template-top-panel-block-margin-right: 20px;
$msv-order-template-top-panel-height: 48px;
$msv-order-template-top-panel-message-min-with: 300px;
$msv-order-template-top-panel-message-max-with: 50%;

$msv-order-template-table-header-height: 43px;
$msv-order-template-table-line-height: 82px;
$msv-order-template-table-line-button-height: 48px;
$msv-order-template-table-line-button-width: 48px;
$msv-order-template-table-line-quantity-width: 80px;
$msv-order-template-table-line-quantity-margin: 0 8px;

$msv-order-template-line-image-size: 64px;
$msv-order-template-line-price-original-font-color: $msv-gray-300;
$msv-order-template-line-price-original-line-height: 16px;
$msv-order-template-line-price-current-font-color: var(--msv-font-primary-color);
$msv-order-template-line-dimension-margin-right: 10px;
$msv-order-template-line-dimension-label-margin: 5px;
$msv-order-template-line-message-padding: 10px;
$order-template-quantity-input: 5px;
$order-template-incr-decr: 5px;
$msv-mobile-port: 450px;
$msv-order-template-margin: 0 20px 40px 0;
$msv-order-template-list-message-empty-margin-bottom: 32px;
$msv-order-template-list-tile-image-max-width: 140px;
$msv-order-template-list-tile-image-margin: 2px;
$msv-order-template-list-tile-image-padding: 4px;

$msv-order-template-add-line-modal-padding: 20px;

$msc-promo-code-alert-padding: 8px;
$msv-open-icon: "\f35d \00a0";
$msv-folder-icon: "\f07c";

@mixin form-control() {
    display: block;
    width: 100%;
    color: $msv-gray-tint;
    background-color: #FFFFFF;
    border: 1px solid #6B727A;
    border-radius: 0;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-transform: none;
    height: 48px;
    padding: 4px 8px;
    background-clip: border-box;
    border-color: transparent;
    position: relative;
    outline-offset: 1px;
}

@mixin is-busy() {
    &.is-busy {
        @include add-spinner(before);
    }
}

@mixin select-quantity() {
    .quantity {
        .quantity-input {
            @include form-control();
            width: $msv-order-template-table-line-quantity-width;
            display: inline-block;
            margin: $msv-order-template-table-line-quantity-margin;
            -moz-appearance: textfield;
            padding: $order-template-quantity-input;
            vertical-align: bottom;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }

        .quantity__controls {
            padding: $order-template-incr-decr;
            padding-left: 0;
            padding-right: 0;
            background: var(--msv-order-template-btn-bg);
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid var(--msv-order-template-btn-border-color);
            min-width: auto;
            width: $msv-order-template-table-line-button-width;
            height: $msv-order-template-table-line-button-height;
            cursor: pointer;

            &:disabled {
                color: $msv-gray-300;
            }
        }

        .increment {
            @include add-icon($msv-Add, after);
            color: var(--msv-order-template-btn-font-color);

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-order-template-btn-font-color);
            }
        }

        .decrement {
            @include add-icon($msv-Remove, after);
            color: var(--msv-order-template-btn-font-color);

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-order-template-btn-font-color);
            }
        }
    }
}

//style presets
:root {
    --msv-order-template-font-size: var(--msv-body-font-size-m);
    --msv-order-template-border: #{$msv-gray-300};
    --msv-order-template-btn-bg: #{$msv-white};
    --msv-order-template-btn-border-color: var(--msv-border-color);
    --msv-order-template-btn-font-color: var(--msv-font-primary-color);
    // heading
    --msv-order-template-heading-font-size: var(--msv-body-font-size-xl);
    --msv-order-template-heading-font-color: var(--msv-font-primary-color);

    // error
    --msv-order-template-error-bg: var(--msv-error-color);
    --msv-order-template-error-border: var(--msv-error-color);
    --msv-order-template-error-font-color: var(--msv-font-secondary-color);

    // link
    --msv-order-template-link-color: var(--msv-font-primary-color);

    // button
    --msv-order-template-secondary-btn-bg: var(--msv-bg-color);
    --msv-order-template-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-order-template-secondary-btn-border: var(--msv-accent-brand-color);
    --msv-order-template-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-order-template-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-order-template-primary-btn-border: var(--msv-accent-brand-color);

    // error
    --msv-promo-code-alert-bg: #{$msv-pink};
    --msv-promo-code-alert-color: var(--msv-error-color);
    --msv-promo-code-alert-border: var(--msv-error-color);
    --msv-promo-code-alert-size: var(--msv-body-font-size-m);
}

.ms-order-template {
    @include font-content(var(--msv-font-weight-normal), var(--msv-order-template-font-size), $msv-line-height-m);

    &__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-order-template-heading-font-size), $msv-line-height-xl);
        color: var(--msv-order-template-heading-font-color);
        height: $msv-order-template-heading-height;
        margin-bottom: $msv-order-template-block-margin-bottom;
    }

    margin-bottom: $msv-order-template-block-margin-bottom;

    .ms-order-template-action-bar {
        &__delete-template-button {
            @include secondary-button(var(--msv-order-template-btn-bg));
            @include vfi();
        }

        &__rename-template-button {
            @include secondary-button(var(--msv-order-template-btn-bg));
            @include vfi();
        }
    }

    .ms-order-template-products-status {
        height: $msv-order-template-top-panel-height;
        min-width: $msv-order-template-top-panel-message-min-with;
        max-width: $msv-order-template-top-panel-message-max-with;

        .msc-alert {
            button.close {
                background-color: transparent;
                border: none;
                float: right;
                cursor: pointer;
            }
        }
    }

    .msc-add-line-to-template__button {
        font-weight: var(--msv-font-weight-normal);
        padding: 0;
        line-height: 100%;
        margin: 2rem 0;

        span {
            @include add-icon($msv-Plus, before);
            padding: 0 1rem 0 1.2rem;
        }
    }

    .add-selected-to-bag > span {
        @include add-icon($msv-Shop, before);
    }

    .remove-selected > span {
        @include add-icon($msv-Cancel, before);
    }

    .remove-selected,
    .add-selected-to-bag {
        padding: 0;
        line-height: 100%;
        margin-bottom: 2rem;

        span {
            padding: 0 1rem 0 1.2rem;
            border-left: 2px solid var(--msv-order-template-border);
            margin-left: 1rem;
        }
    }

    .bulk-selection-field {
        margin: 20px;
    }

    &__message {
        @include font-content-l();
        padding: $msv-order-template-line-message-padding;

        &-failed {
            color: var(--msv-order-template-error-font-color);
            background-color: var(--msv-order-template-error-bg);
            border-color: var(--msv-order-template-error-border);
        }
    }

    &__line-count {
        padding-left: 12px;
        padding-top: 4px;
    }

    &__image-props {
        padding-left: $msv-order-template-list-tile-image-padding;
        padding-top: $msv-order-template-list-tile-image-padding;
        max-width: $msv-order-template-list-tile-image-max-width;
        margin-right: $msv-order-template-list-tile-image-margin;
        margin-left: $msv-order-template-list-tile-image-margin;
    }

    &__add-to-bag-button {
        background: #FFFFFF;
        border: 1px solid #4C833A;
        box-sizing: border-box;
        border-radius: 2px;
        width: 160px;
    }

    &__add-to-bag-button-div {
        padding-bottom: 12px;
        padding-left: 12px;
    }

    &__tile {
        display: flex;
        flex-direction: column;
        position: relative;

        background: #FFFFFF;

        box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.108), 0 1.6px 3.6px rgba(0, 0, 0, 0.132);
        border-radius: 3px;
        margin: $msv-order-template-margin;
    }

    &__template-title {
        position: absolute;
        height: 0;
        left: 0;
        right: 0;
        top: 65px;
        padding-left: 6px;
        border: 1px solid #EDEDED;
    }

    &__list-item-title {
        font-weight: var(--msv-font-weight-bold);
        padding-left: 12px;
        padding-top: 8px;
    }

    &__image-container {
        width: 292px;
        height: 292px;
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 12px;
        background: #F9F9F9;
    }

    &__add-template-button {
        background: #4C833A;
        border-radius: 2px;
        color: $msv-white;
        margin-bottom: 20px;
    }

    &__list-item {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
    }

    &__remove-list {
        @include vfi();
        @include add-icon($msv-x-shape);
        background-color: transparent;
        border: 1px solid transparent;
        color: var(--msv-font-primary-color);
        padding: 6px 12px;
        position: absolute;
        right: 0;
        cursor: pointer;
    }

    &__message-empty,
    &__message-waiting,
    &__message-failed {
        margin-bottom: $msv-order-template-list-message-empty-margin-bottom;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        tr {
            height: $msv-order-template-table-line-height;
            border-bottom: 1px solid var(--msv-order-template-border);
        }

        .ms-order-template-table-line {
            @include select-quantity();

            height: $msv-order-template-table-line-height;

            &__heading {
                height: $msv-order-template-table-header-height;

                th {
                    @include font-content(var(--msv-font-weight-normal), var(--msv-order-template-font-size), $msv-line-height-m);
                    text-align: start;
                }
            }

            &__product {
                &-image {
                    width: $msv-order-template-line-image-size;
                }

                @include image($msv-order-template-line-image-size);

                &-info {
                    &-name {
                        @include font-content(var(--msv-font-weight-bold), var(--msv-order-template-font-size), $msv-line-height-l);
                    }

                    &-dimensions {
                        color: var(--msv-order-template-link-color);
                        display: flex;
                    }

                    &-dimension {
                        margin-right: $msv-order-template-line-dimension-margin-right;

                        .msc-order-template-dimension {
                            &__label {
                                margin-right: $msv-order-template-line-dimension-label-margin;
                            }
                        }
                    }
                }

                &-price {
                    @include font-content-l();

                    .msc-price__strikethrough {
                        font-weight: var(--msv-font-weight-light);
                    }

                    .msc-price__actual {
                        font-weight: var(--msv-font-weight-normal);
                    }

                    .price-strikethrough {
                        color: $msv-order-template-line-price-original-font-color;
                        line-height: $msv-order-template-line-price-original-line-height;
                    }

                    .price-actual {
                        color: $msv-order-template-line-price-current-font-color;
                    }

                    .price-text-screen-reader {
                        display: none;
                    }
                }

                &-unit-of-measure {
                    font-weight: normal;
                }

                &-add-button {
                    font-size: var(--msv-order-template-font-size);

                    @include add-icon($msv-Shop, after);
                    color: var(--msv-order-template-primary-btn-bg);
                    background-color: transparent;
                    width: $msv-order-template-table-line-button-width;
                    height: $msv-order-template-table-line-button-height;
                    padding-left: 0;
                    padding-right: 0;
                }

                &-remove-button {
                    background-color: transparent;

                    @include add-icon($msv-x-shape, after);
                    color: var(--msv-order-template-primary-btn-bg);
                    width: $msv-order-template-table-line-button-width;
                    height: $msv-order-template-table-line-button-height;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

.msc-add-line-to-template {
    &__search-form {
        display: flex;
        height: 32px;
        margin-bottom: 1.25rem;
    }

    &__search-input {
        flex: auto;
        border: 1px solid #6E726E;
        border-right: 0;
        border-radius: 2px 0 0 2px;
        padding-left: 12px;
    }

    &__search-button {
        cursor: pointer;
        background-color: $msv-white;
        border: 1px solid #6E726E;
        border-left: 0;
        border-radius: 0 2px 2px 0;

        &.is-busy {
            @include add-spinner(before);
        }
    }

    &__search-button::after {
        font-family: "Font Awesome 5 Free";
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-weight: 900;
        text-rendering: auto;
        line-height: 1;
        content: "";
    }

    .msc-alert-danger {
        background-color: var(--msv-promo-code-alert-bg);
        border: 1px solid var(--msv-promo-code-alert-border);
        color: var(--msv-promo-code-alert-color);
        font-size: var(--msv-promo-code-alert-size);
        padding: $msc-promo-code-alert-padding;
        margin-top: -1.25rem;
    }

    &__product-list-container {
        overflow-y: scroll;
        margin-right: -1rem;
        max-height: 500px;
    }

    .thumbnail {
        width: 140px;
    }

    #msc-search-modal__product-quantity {
        @include select-quantity();
        border: 1px solid $msv-yellow;
    }

    &__product {
        display: flex;
        margin: 0 1rem 0 0;
        border-top: 1px solid $msv-gray-400;
        padding: 1rem 0;

        &__attributes {
            flex: 1;
            width: 100%;
            padding: 0 1rem;
        }

        &__id,
        &__unit-price,
        &__uom {
            font-size: smaller;
            font-weight: 100;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        &__name > span {
            font-weight: bold;
        }

        &__total-price {
            padding-top: 16px;
            text-align: right;
        }

        &__select-button {
            @include is-busy();
            position: absolute;
            bottom: 0;
            right: 0;
            white-space: nowrap;
        }
    }

    &__product-config {
        @include select-quantity();
        display: flex;
        margin: 0 1rem 0 0;
        border-top: 1px solid $msv-gray-400;
        padding: $msv-order-template-add-line-modal-padding 0;

        &__dimensions {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            div {
                margin-right: 1rem;
            }

            .msc-dropdown__select {
                margin-top: 0.25rem;

                display: block;
                width: 100%;
                color: $msv-gray-tint;
                background-color: #FFFFFF;
                border: 1px solid #6B727A;
                border-radius: 0;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                text-transform: none;
                height: 48px;
                padding: 4px 8px;
                background-clip: border-box;
                border-color: transparent;
                position: relative;
                outline-offset: 1px;
            }
        }

        .quantity-container {
            padding-bottom: 5px;
        }
    }

    &__product-price-container {
        position: relative;
    }

    &__not-found {
        &__icon {
            @include add-icon($msv-folder-icon, after);
            background-color: $msv-gray-400;
            margin: 5rem auto 3rem;
            width: 110px;
            padding: 15px;
            border-radius: 55px;
            font-size: 5rem;
        }

        &__msg {
            text-align: center;
            font-size: 1.5rem;
        }

        &__remediation-instructions {
            text-align: center;
            margin: 1.5rem 0 5rem;
        }
    }

    &__add-confirmation {
        padding: 10px;
    }

    &__add-configured-product-button {
        &.is-busy {
            @include add-spinner(before);
        }
    }
}

.msc-modal .msc-add-line-to-template .msc-modal__content .msc-modal__header {
    justify-content: unset;
}

.msc-modal .msc-add-line-to-template {
    .msc-modal__back-button {
        @include add-icon($msv-BackArrow);
        @include vfi();
        background-color: transparent;
        border: 0;
        color: var(--msv-font-primary-color);
        font-size: 20px;
        margin-right: 1rem;
        padding: $msv-modal-button-padding;
    }
}
