$msv-business-sign-up-width: 100%;
$msv-business-sign-up-width-m: 80%;
$msv-business-sign-up-input-margin-top: 4px;
$msv-business-sign-up-message-margin-top: 20px;
$msv-business-sign-up-message-padding: 20px;
$msv-business-sign-up-success-border: 1px solid $msv-text-color;
$msv-business-sign-up-success-bg-color: $msv-gray-60;
$msv-business-sign-up-success-text-color: $msv-text-color;
$msv-business-sign-up-error-border: 1px solid $msv-white;
$msv-business-sign-up-error-bg-color: $msv-error-color;
$msv-business-sign-up-email-verification-buttons-margin-top: 12px;
$msv-business-sign-up-button-group-spacing: 32px;
$msv-business-sign-up-button-margin-top: 20px;
$msv-business-sign-up-button-margin-spacing: 20px;
$msv-business-sign-up-disclaimer-margin-top: 20px;

$msv-business-sign-up-border-radius: 2px;
$msv-business-sign-up-form-item-margin: 20px 0 0;
$msv-business-sign-up-input-border: 1px solid $msv-gray-120;
$msv-business-sign-up-input-padding: 6px 8px;
$msv-business-sign-up-input-size: 48px;
$msv-business-sign-up-input-spacing: 10px;
$msv-business-sign-up-label-margin: 0 0 5px 0;
$msv-business-sign-up-text-area-size: 173px;

.ms-business-sign-up {
    @include font-content-m();
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: $msv-business-sign-up-width;
    }

    &__heading {
        @include font-content-xl();
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
    }

    &__form-item {
        display: flex;
        flex-direction: column;
        margin: $msv-business-sign-up-form-item-margin;
        margin-right: $msv-business-sign-up-input-spacing;

        &.width-25 {
            flex: 0 1;
            flex-basis: calc(25% - #{$msv-business-sign-up-input-spacing});
        }

        &.width-50 {
            flex: 0 1;
            flex-basis: calc(50% - #{$msv-business-sign-up-input-spacing});
        }

        &.width-100 {
            flex: 0 1 100%;
            margin-left: 0;
            margin-right: 0;
        }

        &-label {
            margin: $msv-business-sign-up-label-margin;
        }

        &-input, .msc-address-form__input, .msc-address-form__dropdown {
            @include font-content-l();
            @include vfi();
            border: $msv-business-sign-up-input-border;
            border-radius: $msv-business-sign-up-border-radius;
            box-sizing: border-box;
            display: block;
            height: $msv-business-sign-up-input-size;
            padding: $msv-business-sign-up-input-padding;
            width: 100%;
        }

        // country and input fields from the address form need to 50% length according to the design doc
        .msc-address-form__item-threeletterisoregionname, .msc-address-form__item-phone {
            display: inline-block;
            margin-bottom: 0;
            width: 50%;
        }

        .msc-address-form__item-threeletterisoregionname {
            margin-right: $msv-business-sign-up-input-spacing;
            width: calc(50% - #{$msv-business-sign-up-input-spacing});
        }

        &-input-area {
            @include font-content-l();
            @include vfi();
            border: $msv-business-sign-up-input-border;
            border-radius: $msv-business-sign-up-border-radius;
            box-sizing: border-box;
            display: block;
            height: $msv-business-sign-up-text-area-size;
            padding: $msv-business-sign-up-input-padding;
            width: 100%;
        }
    }

    &__page-error {
        color: var(--msv-font-secondary-color);
        background-color: $msv-business-sign-up-error-bg-color;
        border: $msv-business-sign-up-error-border;
        display: block;
        margin-top: $msv-business-sign-up-message-margin-top;
        padding: $msv-business-sign-up-message-padding;
    }

    &__create-button {
        @include primary-button();
        margin-top: $msv-business-sign-up-button-margin-top;
        margin-right: $msv-business-sign-up-button-margin-spacing;

        &[disabled] {
            &:focus {
                border: none;
            }
        }
    }

    &__cancel-button {
        @include secondary-button();
        margin-top: $msv-business-sign-up-button-margin-top;
        margin-right: $msv-business-sign-up-button-margin-spacing;
    }

    &__disclaimer {
        margin-top: $msv-business-sign-up-disclaimer-margin-top;
        width: 100%;

        a {
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: $msv-breakpoint-l) {
        &__container {
            width: $msv-business-sign-up-width-m;
        }

        .msc-address-form__item-threeletterisoregionname {
            display: block;
            margin-bottom: 10px;
            width: 100%;
        }

        .msc-address-form__item-phone {
            display: block;
            width: 100%;
        }

        &__form-item {
            margin-right: 10px;

            &.width-25, &.width-50, &.width-100 {
                flex-basis: 100%;
            }
        }

        &__create-button, &__cancel-button {
            margin-right: 0;
            width: 100%;
        }
    }
}
