$reviews-list-border-color: $msv-text-color;
$reviews-list-text-color: $msv-text-color;
$reviews-list-link-color: $msv-link-color;
$msv-review-repsponse-border-color: #C8C6C4;
$msv-reviews-list-date-color: $msv-gray-120;
$msv-reviews-card-border-width: 1px;

$msv-reviews-list-reviews-width: 100%;

$msv-reviews-list-refiners-margin-bottom: 10px;
$msv-reviews-list-refiners-margin-top-mobile-viewport: 5px;

$msv-reviews-list-card-header-max-width: 120px;
$msv-reviews-list-card-header-margin-right: 70px;

$msv-rating-padding-top: 15px;
$msv-msc-l-dropdown-label-margin-right: 5px;
$msv-msc-l-dropdown-label-margin-left: 20px;

$msv-msc-l-dropdown-label-margin-left-mobile-viewport: 2px;

$msv-msc-l-dropdown-padding: 6px 5px;
$msv-msc-l-dropdown-padding-mobile-viewport: 0;

$msv-reviewslist-like-icon-margin-right: 4px;

$msv-reviews-list-dislike-button-padding-right: 22px;
$msv-reviews-list-dislike-button-padding-right: 4px;

$msv-reviews-list-card-padding: 16px 0;

$msv-reviews-response-margin: 12px 0 0 70px;
$msv-reviews-response-width: calc(100% - 70px);
$msv-reviews-response-width-m: 500px;
$msv-reviews-response-icon-width: 4px;
$msv-reviews-response-icon-left: -20px;
$msv-reviews-response-text-margin-top: 4px;

$msv-reviews-list-pagination-icon-spacing: 3px;
$msv-reviews-list-empty-message-padding: 24px;
$msv-reviews-list-user-review-bg: $msv-white;

.ms-reviews-list {
    color: var(--msv-font-primary-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &__empty-message {
        padding-bottom: $msv-reviews-list-empty-message-padding;
    }

    &__refiners {
        margin-bottom: $msv-reviews-list-refiners-margin-bottom;

        .msc-l-dropdown {
            color: var(--msv-font-primary-color);
            padding: $msv-msc-l-dropdown-padding;
            display: inline-block;
            vertical-align: middle;

            &__label {
                display: inline-block;
                font-weight: bold;
                margin-right: $msv-msc-l-dropdown-label-margin-right;
                vertical-align: middle;
                margin-left: $msv-msc-l-dropdown-label-margin-left;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            margin-top: $msv-reviews-list-refiners-margin-top-mobile-viewport;

            .msc-l-dropdown__label {
                margin-left: $msv-msc-l-dropdown-label-margin-left-mobile-viewport;
            }

            .msc-l-dropdown {
                padding: $msv-msc-l-dropdown-padding-mobile-viewport;
            }
        }
    }

    &__reviews {
        flex-basis: $msv-reviews-list-reviews-width;
        margin-bottom: 20px;
    }

    &__user-review {
        background: $msv-reviews-list-user-review-bg;
    }

    &__like,
    &__dislike,
    &__report,
    &__edit {
        @include button-link(var(--msv-accent-brand-color));
        @include vfi();
        vertical-align: middle;
    }

    &__submit,
    &__success {
        @include primary-button();
        margin-right: 8px;
    }

    &__cancel {
        @include secondary-button();
    }

    &__like {
        @include add-icon($msv-Like, before, $msv-outline-icon-weight);

        &::before {
            margin-right: $msv-reviewslist-like-icon-margin-right;
        }
    }

    &__dislike {
        @include add-icon($msv-Dislike, before, $msv-outline-icon-weight);
        padding-right: $msv-reviews-list-dislike-button-padding-right;
        border-right: $msv-reviews-card-border-width solid $msv-review-repsponse-border-color;

        &::before {
            margin-right: $msv-reviews-list-dislike-button-padding-right;
        }
    }

    &__date {
        color: $msv-reviews-list-date-color;
    }

    &__card {
        border-top: $msv-reviews-card-border-width solid $reviews-list-border-color;
        padding: $msv-reviews-list-card-padding;

        &-header {
            margin-right: $msv-reviews-list-card-header-margin-right;
            flex-shrink: 0;
            max-width: $msv-reviews-list-card-header-max-width;
        }

        &-body {
            flex-grow: 1;
        }

        &:last-child {
            border-bottom: $msv-reviews-card-border-width solid $reviews-list-border-color;
        }

        @media (min-width: $msv-breakpoint-m) {
            display: flex;

            &-controls {
                text-align: right;
            }
        }
    }

    &__helpful {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
    }

    &__review {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__text {
        flex-basis: 100%;

        &::after {
            content: "";
        }
    }

    &__response {
        margin: $msv-reviews-response-margin;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: $msv-reviews-response-width;

        &::before {
            content: "";
            width: $msv-reviews-response-icon-width;
            height: 100%;
            position: absolute;
            left: $msv-reviews-response-icon-left;
            background-color: $msv-review-repsponse-border-color;
        }

        &-text {
            flex-basis: 100%;
            margin-top: $msv-reviews-response-text-margin-top;
        }

        &-date {
            color: $msv-reviews-list-date-color;
        }

        @media (min-width: $msv-breakpoint-m) {
            width: $msv-reviews-response-width-m;
        }
    }

    &__page-controls {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
    }

    &__next {
        margin-left: auto;
    }

    &__next,
    &__previous {
        color: var(--msv-font-primary-color);

        @include vfi();
        background-color: transparent;
        border: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__previous::before {
        @include msv-icon();
        content: $msv-ChevronLeft;
        margin-right: $msv-reviews-list-pagination-icon-spacing;
    }

    &__next::after {
        @include msv-icon();
        content: $msv-ChevronRight;
        margin-left: $msv-reviews-list-pagination-icon-spacing;
    }

    &__report-error {
        margin-bottom: 0;
    }
}

.ms-report-review-modal {
    fieldset {
        border-color: transparent;
    }

    &-pair {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
}

.msc-radio-label {
    @include vfi();
    height: 18px;
    margin-right: 8px;
}
