// Base class
.msc-flyout {
    position: absolute;
    z-index: 1060;
    display: block;
    margin: 0;

    @include reset-text();
    opacity: 0;

    &.show { opacity: 1; }
}

// Wrapper for the tooltip content
.msc-flyout-inner {
    padding: 0;
    text-align: left;
}
