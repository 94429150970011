//spacing
$header-container-spacing-desktop: 20px;
$header-container-spacing-mobile: 0;
$signin-info-spacing-left: 8px;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 20px;
$heading-padding-top: 25px;
$signin-info-padding: 12px 14px;
$header-height: 48px;
$header-mobile-height: 48px;
$header-icon-width: 48px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$signin-link-padding: 0 20px;
$header_container-max-width: $msv-breakpoint-xl;
$header-myaccount-margin-left: 8px;
$header-cart-icon-margin-left: 4px;
$header-icon-size: 16px;
$popover-menu-border-color: $msv-gray-120;
$popover-menu-item-height: 48px;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 20px;

//style presets
:root {
    --msv-header-bg: var(--msv-bg-color);
    --msv-header-font-color: var(--msv-font-primary-color);
    --msv-header-font-size: #{$header-icon-size};
}

.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);

    margin-left: auto;
    margin-right: auto;
    padding-left: $header-container-spacing-mobile;
    padding-right: $header-container-spacing-mobile;
    padding-top: $heading-padding-top;

    &__container {
        .ms-header__logo a {
            display: block;

            @include vfi();

            &:focus {
                outline-offset: -2px;
            }
        }
    }

    .ms-header__preferred-store-text {
        color: var(--msv-header-font-color);
        font-size: var(--msv-header-font-size);
        margin-left: $header-myaccount-margin-left;
    }

    .ms-header__preferred-store-btn {
        @include add-icon($msv-Shop-Address);
    }

    .ms-search__icon,
    .ms-header__signin-button,
    .msc-wishlist-icon,
    .msc-cart-icon,
    .ms-header__profile-button {
        color: var(--msv-header-font-color);
        font-size: var(--msv-header-font-size);

        &:focus {
            outline-offset: -1px;
        }
    }

    // hiding the header icons from login page
    &.hide-header-options {
        .ms-header__nav-icon,
        .ms-header__account-info,
        .msc-cart-icon,
        .msc-wishlist-icon {
            display: none;
        }
    }

    .ms-nav.mobile-vp {
        display: none;
    }

    .msc-btn {
        background-color: var(--msv-header-bg);
        height: $header-height;
    }

    .ms-header__topbar {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        height: $header-mobile-height;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .ms-header__account-info {
        font-size: var(--msv-header-font-size);
        display: none;
        font-family: $msv-primary-font-family;
        justify-content: flex-end;
        margin-left: $signin-info-spacing-left;
        height: $header-height;

        &__account-link-container {
            height: $account-link-height;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        a {
            align-items: center;
            display: flex;
            font-size: $msv-text-size;
            height: 48px;
            line-height: $msv-text-line-height;
            padding: $signin-link-padding;

            &:hover {
                text-decoration: none;
            }
        }

        .ms-header__signin-button {
            background-color: var(--msv-header-bg);
            color: var(--msv-header-font-color);
            font-size: var(--msv-header-font-size);

            &:focus {
                outline-offset: -1px;
            }
        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: 1px solid $popover-menu-border-color;
            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: normal;
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: "";
                    display: block;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: $popover-menu-border-color;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-header-border);
                    top: 1px;
                }
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-wishlist-icon {
        &:hover {
            text-decoration: none;
        }
    }

    .ms-header__wishlist-desktop {
        display: none;
        width: $header-icon-width;
        height: $header-height;
        align-items: center;

        @include add-icon($msv-Heart, before, 400);

        &::before {
            font-size: var(--msv-header-font-size);
            width: $header-icon-width;
            vertical-align: text-bottom;
            text-align: center;
        }

        .msc-wishlist-icon__text {
            display: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .ms-header__nav-icon {
        background: var(--msv-header-bg);
        width: $header-icon-width;
        height: $header-height;
        display: flex;
        align-items: center;

        &:focus {
            outline-offset: -1px;
        }

        &::before {
            @include msv-icon();
            content: $msv-GlobalNavButton;
            font-size: var(--msv-header-font-size);
            width: $header-icon-width;
            vertical-align: text-bottom;
            text-align: center;
        }

        &:hover {
            text-decoration: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-icon {
        width: $header-icon-width;
        height: $header-height;
        display: flex;
        align-items: center;

        &::before {
            @include msv-icon();
            content: $msv-shopping-bag;
            font-size: var(--msv-header-font-size);
            width: $header-icon-width;
            vertical-align: text-bottom;
            text-align: center;
        }

        &:hover {
            text-decoration: none;
        }

        &__count {
            @include font-content-s();
            margin-left: $header-cart-icon-margin-left;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .ms-nav.desktop-vp {
            display: none;
        }

        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        &__container {
            .ms-header__topbar {
                .ms-site-picker {
                    display: none;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-l) {
        padding-left: $header-container-spacing-desktop;
        padding-right: $header-container-spacing-desktop;

        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: var(--msv-header-font-size);
                margin-left: $header-myaccount-margin-left;
                text-align: center;
                vertical-align: text-bottom;
            }
        }

        &__profile-button[aria-expanded="true"] {
            &::after {
                content: $msv-ChevronUp;
            }
        }

        &__profile-button[aria-expanded="false"] {
            &::after {
                content: $msv-ChevronDown;
            }
        }

        &__divider {
            background: $msv-divider-color;
            height: $divider-margin-height;
            margin-left: $divider-margin-left;
            margin-right: $divider-margin-right;
            width: $divider-margin-width;
        }

        .ms-search {
            &__icon {
                color: var(--msv-header-font-color);
                font-weight: normal;

                &:focus {
                    outline-offset: -1px;
                }
            }
        }

        .ms-header__wishlist-desktop {
            display: flex;
        }

        .ms-nav.desktop-vp {
            display: flex;
        }

        .ms-header__nav-icon {
            display: none;
        }

        .ms-header__account-info {
            display: flex;
        }
    }

    @supports (-webkit-touch-callout: none) {
        .ms-header__preferred-store-btn {
            &:focus {
                border: none;
                outline: 0;
            }
        }
    }
}

.msc-modal {
    .ms-header__mobile-hamburger {
        background: var(--msv-header-bg);
        height: 100%;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;

        .msc-modal__content {
            height: 100%;
            padding: 12px;

            .ms-header__mobile-hamburger-menu-body {
                flex: 1 1 auto;
                overflow: hidden;
                padding: 0;
                position: relative;

                .msc-btn {
                    font-size: 16px;
                    height: unset;
                    line-height: 22px;
                    padding: 12px 8px;
                }
            }

            .ms-nav {
                padding-left: $navigation-spacing-left;
            }

            .msc-modal__header {
                display: block;
                height: $header-height;
                padding: 0;

                .msc-modal__close-button {
                    color: var(--msv-header-font-color);
                    left: 0;
                    margin: 0;
                    opacity: 1;

                    &:focus {
                        outline-offset: -1px;
                    }

                    &::before {
                        @include msv-icon();
                        content: $msv-Cancel;
                        font-size: 20px;
                        position: unset;
                        text-align: center;
                        vertical-align: text-bottom;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .msc-btn {
            display: block;
            background-color: var(--msv-header-bg);
        }

        .ms-header__mobile-hamburger-menu-links {
            border-top: 1px solid $msv-gray-60;

            .ms-signin-info__account-link-button,
            .ms-header__signout-button,
            .ms-header__signin-button,
            .msc-wishlist-icon {
                color: $msv-text-color;
                font-weight: normal;

                &:focus {
                    outline-offset: -1px;
                }
            }
        }
    }
}
