[dir="rtl"] {
    $msv-tile-list-flipper-position-mobile: -15px;
    $msv-tile-list-flipper-position-tablet: -15px;
    $msv-tile-list-flipper-position-desktop: -30px;
    $msv-tile-list-title-initial-position-right: 30%;
    $msv-tile-list-thumbnail-image-initial-position-right: 10%;
    $msv-tile-list-title-effect-position-right: 10%;
    $msv-tile-list-text-effect-position-right: 10%;
    $msv-tile-list-links-effect-position-right: 10%;

    .ms-tile-list {
        .msc-ss-carousel {
            position: relative;

            .msc-ss-carousel-slide {
                float: left;
            }

            .msc-flipper {
                &.msc-ss-carousel__flipper {
                    left: -10px;
                    right: auto;

                    @media screen and (max-width: $msv-breakpoint-m) {
                        left: $msv-tile-list-flipper-position-mobile;
                    }

                    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                        left: $msv-tile-list-flipper-position-tablet;
                    }

                    @media screen and (min-width: $msv-breakpoint-l) {
                        left: $msv-tile-list-flipper-position-desktop;
                    }
                }

                &.msc-ss-carousel__flipper--next {
                    right: -10px;
                    left: auto;

                    @media screen and (max-width: $msv-breakpoint-m) {
                        right: $msv-tile-list-flipper-position-mobile;
                    }

                    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                        right: $msv-tile-list-flipper-position-tablet;
                    }

                    @media screen and (min-width: $msv-breakpoint-l) {
                        right: $msv-tile-list-flipper-position-desktop;
                    }
                }
            }

            .ms-tile__item {
                .ms-tile-list__heading {
                    .ms-tile-list__title {
                        right: $msv-tile-list-title-initial-position-right;
                    }
                }

                .ms-tile-list__thumbnail_image {
                    right: $msv-tile-list-thumbnail-image-initial-position-right;
                    left: auto;
                }

                .ms-tile-list-container {
                    &:hover,
                    &:focus,
                    &:focus-within {
                        .ms-tile-list__heading {
                            .ms-tile-list__title {
                                right: $msv-tile-list-title-effect-position-right;
                                left: auto;
                            }

                            .ms-tile-list__text {
                                right: $msv-tile-list-text-effect-position-right;
                            }

                            .ms-tile-list__links {
                                right: $msv-tile-list-links-effect-position-right;
                            }
                        }
                    }
                }
            }
        }
    }
}
