$rtl-header-myaccount-margin-right: 8px;
$rtl-cart-icon-spacing-right: 20px;
$rtl-header-cart-icon-margin-right: 4px;

[dir="rtl"] {
    .ms-header {
        .msc-cart-icon {
            &__count {
                margin-left: unset;
                margin-right: $rtl-header-cart-icon-margin-right;
            }
        }

        @media (min-width: $msv-breakpoint-l) {
            .ms-cart-icon {
                padding-left: unset;
                padding-right: $rtl-cart-icon-spacing-right;
            }

            &__profile-button {
                &::after {
                    margin-left: unset;
                    margin-right: $rtl-header-myaccount-margin-right;
                }
            }
        }
    }
}
