$msv-subscribe-subscribe-button-mobile-margin: 20px 0;
$msv-subscribe-button-margin: 0 20px 0 0;

[dir="rtl"] {
    .ms-subscribe {
        .ms-subscribe__details {
            .ms-subscribe__details__form {
                @media screen and (max-width: $msv-breakpoint-m) {
                    &__submit {
                        margin: $msv-subscribe-subscribe-button-mobile-margin;
                    }
                }

                @media screen and (min-width: $msv-breakpoint-m) {
                    &__submit {
                        margin: $msv-subscribe-button-margin;
                    }
                }
            }
        }
    }
}
