$msv-account-profile-edit-width: 40%;
$msv-account-profile-edit-width-m: 80%;

$msv-account-profile-edit-message-margin-top: 20px;
$msv-account-profile-edit-message-padding: 20px;

// set for slate theme
$msv-account-profile-edit-success-border: 1px solid $msv-text-color;
$msv-account-profile-edit-success-bg-color: $msv-gray-60;
$msv-account-profile-edit-success-text-color: $msv-text-color;
$msv-account-profile-edit-error-border: 1px solid $msv-text-color;
$msv-account-profile-edit-error-bg-color: $msv-gray-60;
$msv-account-profile-edit-error-text-color: $msv-text-color;

$msv-account-profile-edit-account-item-margin-top: 20px;
$msv-account-profile-edit-input-margin-top: 4px;

$msv-account-profile-edit-button-group-spacing: 32px;
$msv-account-profile-edit-button-margin-top: 20px;

.ms-account-profile-edit {
    @include font-content-m();
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: $msv-account-profile-edit-width;
    }

    &__heading {
        @include font-content-xl();
    }

    &__account-item {
        margin-top: $msv-account-profile-edit-account-item-margin-top;

        &-input {
            @include form-input-el();
            margin-top: $msv-account-profile-edit-input-margin-top;
            width: 100%;
        }
    }

    &__page-success {
        color: $msv-account-profile-edit-success-text-color;
        background-color: $msv-account-profile-edit-success-bg-color;
        border: $msv-account-profile-edit-success-border;
        display: none;
        margin-top: $msv-account-profile-edit-message-margin-top;
        padding: $msv-account-profile-edit-message-padding;
    }

    &__page-error {
        color: $msv-account-profile-edit-error-text-color;
        background-color: $msv-account-profile-edit-error-bg-color;
        border: $msv-account-profile-edit-error-border;
        display: none;
        margin-top: $msv-account-profile-edit-message-margin-top;
        padding: $msv-account-profile-edit-message-padding;
    }

    &__save-button {
        @include primary-button();
        margin-top: $msv-account-profile-edit-button-margin-top;
        width: 100%;
    }

    &__cancel-button {
        @include secondary-button();
        margin-top: $msv-account-profile-edit-button-margin-top;
        width: 100%;
    }

    &__verifying-modal,
    &__verifying-modal-message {
        display: none;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: $msv-account-profile-edit-width-m;
        }
    }
}
