.msc-global-notifications-list-container {
    /* stylelint-disable declaration-no-important -- Width should be fixed and be the same for all the notifications. */
    min-width: 340px !important;
    max-width: 340px !important;
    width: 340px !important;
    /* stylelint-enable declaration-no-important */

    // Enable click through.
    pointer-events: none;

    position: fixed;
    right: 30px;
    z-index: 1010;
    bottom: 0;

    @media (max-width: $msv-breakpoint-m) {
        right: unset;
        left: 50%;
        transform: translate(-50%, 0);
    }

    * {
        pointer-events: auto;
    }

    ::-webkit-scrollbar {
        background: none;
        width: 8px;
        pointer-events: auto;
    }

    ::-webkit-scrollbar-thumb {
        background: #D5D5D5;
        width: 8px;
        border-radius: 8px;
        pointer-events: auto;
    }

    &__scrollable {
        overflow: auto;
        padding: 20px 10px;
        max-height: calc(100vh - 100px);
    }
}
