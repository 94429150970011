$msv-checkout-delivery-options-description-font-size: 14px;
$msv-checkout-delivery-options-description-line-height: 20px;

$msv-checkout-delivery-options-height: 18px;
$msv-checkout-delivery-options-width: 18px;

$msv-checkout-description-margin-left: 9px;

$msv-checkout-option-margin-bottom: 28px;

$msv-checkout-delivery-options-price-margin-left: 12px;
$msv-checkout-delivery-options-description-margin-left: 0;

.ms-checkout-delivery-options {
    &__price {
        margin-left: auto;
        font-weight: $msv-font-weight-600;
    }

    &__description {
        margin-left: $msv-checkout-description-margin-left;
    }

    &__option {
        margin-bottom: $msv-checkout-option-margin-bottom;
        display: flex;

        &-selected {
            font-weight: $msv-font-weight-600;

            .ms-checkout-delivery-options__price {
                margin-left: $msv-checkout-delivery-options-price-margin-left;
            }

            .ms-checkout-delivery-options_description {
                margin-left: $msv-checkout-delivery-options-description-margin-left;
            }
        }
    }

    &__input-radio {
        @include vfi();
        width: $msv-checkout-delivery-options-width;
        height: $msv-checkout-delivery-options-height;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    &__price,
    &__description {
        font-size: $msv-text-size;
        line-height: $msv-text-line-height;
        align-self: center;
    }
}
